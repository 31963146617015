/**
 * Type: テンプレート
 * What: 特設・イベント案内
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import HtmlEscape from '../util/htmlEscape';
import { Btn, Button, Text } from '../components/Btn';

const pageSlug = 'special';

export const SpecialTemplate = ({ content, title }) => (
  <>
    <SEO title={title} />
    <BreadcrumbComponent
      hierarchy={2}
      bread2url="special/"
      bread2title={title}
    />
    <section className="section" id="specialPost">
      <div className="container">
        <div
          dangerouslySetInnerHTML={{ __html: HtmlEscape(content) }}
          className="entry-content"
        />
      </div>
      <div className="btn-wrap">
        <Button href="/">トップへ戻る</Button>
      </div>
    </section>
  </>
);

SpecialTemplate.defaultProps = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const SpecialPost = ({ data }) => {
  const { wordpressWpSpecial: special } = data;

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={special.title} />
      <SpecialTemplate content={special.content} title={special.title} />
    </Layout>
  );
};

SpecialPost.defaultProps = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.string,
  }),
};

export default SpecialPost;

export const pageQuery = graphql`
  query SpecialeById($id: String!) {
    wordpressWpSpecial(id: { eq: $id }) {
      title
      content
    }
  }
`;
